@import "~normalize.css/normalize.css";

$headerHeight: 5rem;

body {
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100vw;
  height: 100vh;
}

@keyframes header-shrink {
  0% {
    height: 100vh;
  }

  100% {
    height: $headerHeight;
  }
}

#header {
  width: 100%;
  z-index: 110;
  text-align: center;
  position: absolute;
  background: white;

  animation-name: header-shrink;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-play-state: running;

  img {
    max-height: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

#main {
  position: absolute;
  top: $headerHeight;
  bottom: 0;
  left: 0;
  right: 0;
}

.frames {
  display: flex;
  height: 100%;
  width: 100%;

  > div {
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  #vmix-viewer {
    min-width: 50%;
  }

  #internet-clicker {
    min-width: 10rem;
  }
}

.gutter {
  //background-color: #eee;

  background-repeat: no-repeat;
  background-position: 50%;

  &.gutter-horizontal {
    background-image: url('img/grips/vertical.png');
    cursor: col-resize;
  }

  &.gutter-vertical {
    background-image: url('img/grips/horizontal.png');
    cursor: row-resize;
  }
}

#setup {
  margin: auto 1rem;

  ul {
    list-style: none;
  }

  li {
    display: flex;
  }

  label {
    width: 10rem;
    flex: content;
  }

  input {
    flex: auto;
  }
}
